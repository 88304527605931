import * as React from 'react'
import styles from './errorMessage-style.css'
import Heading from '../Heading/Heading'
import Rte from '../Rte/Rte'
type Props = {
  heading?: string
  message?: string
}

function ErrorMessage({ heading, message }: Props) {
  return (
    <div className={styles.errorMessage}>
      <Heading className={styles.heading} level={2} tag={'h3'}>
        {heading || 'Error'}
      </Heading>
      <Rte className={styles.errorMarkdown} source={message || 'Try refreshing the page'} />
    </div>
  )
}

ErrorMessage.displayName = 'ErrorMessage'
ErrorMessage.defaultProps = {}
export default ErrorMessage