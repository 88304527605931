import cx from 'classnames'
import React, {RefObject, useState} from 'react'
import Slider from 'react-slick'

import type { LinkViewModel } from '../../view-models/LinkViewModel'
import { trackEvent } from '../../utils/gtag'
import Heading from '../Heading/Heading'
import styles from './carousel-style.css'
import useLockScroll from './useLockScroll'

type Props = {
  items: Array<Record<string, any>>
  renderItems?: (...args: Array<any>) => any
  viewMore?: LinkViewModel
  moduleName?: string
  slidesToShow?: number
  slidesToScroll?: number
}

//that is only for mobile
const Carousel = ({
  items,
  renderItems,
  viewMore,
  moduleName,
  slidesToShow,
  slidesToScroll,
}: Props) => {
  const ref = useLockScroll() as RefObject<HTMLDivElement>
  const [lastSlide, setLastSlide] = useState(false)
  const settings = {
    className: styles.carouselContainer,
    dots: true,
    arrows: true,
    speed: 500,
    slidesToShow: slidesToShow || 1.3,
    slidesToScroll: slidesToScroll || 1,
    cssEase: 'cubic-bezier(.22,.41,0,1.19)',
    infinite: false,
    beforeChange: (current, next) => {
      if (next === 1) {
        trackEvent({
          event: 'modules-interaktion',
          eventData: moduleName || '',
        })
      }
    },
    afterChange: (current) => {
      // add 1 to current so index starts at 1.
      // if index is equal items.length or if the index plus slidesToScroll is more than items.length we know if it's the last slide
      const isLast = current + 1 === items.length || current + 1 + slidesToScroll > items.length
      setLastSlide(isLast)
    },
  }
  if (viewMore)
    items.push({
      link: viewMore,
    })
  if (!items) return null

  return (
    <div className={styles.carousel}>
      <div className={cx(styles.slickContainer, !!lastSlide ? styles.lastSlide : '')} ref={ref}>
        <Slider {...settings}>
          {typeof renderItems === 'function' ? (
            renderItems(items, styles.carouselCard)
          ) : (
            <Heading level={3}>Please provide renderItems() that returns slider items.</Heading>
          )}
        </Slider>
      </div>
    </div>
  )
}

export default Carousel